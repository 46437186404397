<template>
  <div>
    <div class="step-header">
      <div v-for="step in steps" :key="step.number" :class="['step-item', { 'selected': isActiveStep(step.number) }]" @click="attemptNavigateToStep(step.number)">
        <div :class="['vertical-center', { 'add-spacer-after': step.number !== steps.length }]">
          <span :class="`stepper-label status-${stepStatus(step.number)} step-${step.number}`">
            <svg-icon name="checkmark-no-circle" class="base-icon" v-if="stepIsComplete(step.number)"></svg-icon>
            <span v-else>{{ step.number }}</span>
          </span>
          <span class="stepper-label-text">{{ step.label }}</span>
        </div>
      </div>
    </div>

    <slot
      :move-to-next-step="nextStep"
      :move-to-prev-step="previousStep"
      :register-validator="registerValidator"
      :register-payload-fetcher="registerPayloadFetcher">
    </slot>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'Stepper',

  components: {
    SvgIcon
  },

  props: {
    steps: {
      type: Array,
      required: true
    },

    activeStep: {
      type: Number,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      validators: {},
      payloadFetchers: {}
    };
  },

  mounted() {
    this.setSpacerCustomCssProperties();
    window.addEventListener('resize', this.setSpacerCustomCssProperties);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setSpacerCustomCssProperties);
  },

  methods: {
    previousStep() {
      this.attemptNavigateToStep(this.activeStep - 1);
    },

    nextStep(payload) {
      if (this.activeStep < this.steps.length) {
        const newActiveStep = this.activeStep + 1;
        const oldActiveStep = this.activeStep;
        payload.rfq = { ...payload.rfq, shouldUpdateMaxStep: true };
        this.$emit('step-change', { newActiveStep: newActiveStep, oldActiveStep: oldActiveStep, stepChangePayload: payload });
      }
    },

    isActiveStep(stepIndex) {
      return stepIndex === this.activeStep;
    },

    stepStatus(stepIndex) {
      if (stepIndex === this.activeStep) {
        return 'active';
      }
      else if (stepIndex < this.activeStep) {
        return 'complete';
      }
      else {
        return 'incomplete';
      }
    },

    stepIsComplete(stepIndex) {
      return stepIndex < this.activeStep;
    },

    setSpacerCustomCssProperties() {
      const stepHeader = document.querySelector('.step-header');

      if (stepHeader) {
        const stepHeaderWidth = window.getComputedStyle(stepHeader).width;
        document.documentElement.style.setProperty('--total-width', stepHeaderWidth);
      }

      let itemsWidth = 0;
      document.querySelectorAll('.step-item').forEach((stepItem) => {
        itemsWidth += stepItem.offsetWidth;
      });

      document.documentElement.style.setProperty('--items-width', itemsWidth + 'px');
      document.documentElement.style.setProperty('--spacer-count', this.steps.length - 1);
    },

    registerValidator(stepNumber, validator) {
      this.validators[stepNumber] = validator;
    },

    registerPayloadFetcher(stepNumber, fetcher) {
      this.payloadFetchers[stepNumber] = fetcher;
    },

    attemptNavigateToStep(stepNumber) {
      const currentValidator = this.validators[this.activeStep];
      const currentPayloadFetcher = this.payloadFetchers[this.activeStep];

      if (this.goingBackwards(stepNumber)) {
        this.handleBackwardNavigation(stepNumber, currentValidator, currentPayloadFetcher);
      }
      else {
        this.handleForwardNavigation(stepNumber, currentValidator, currentPayloadFetcher);
      }
    },

    validateCurrentStep() {
      const validator = this.validators[this.activeStep];
      if (validator) {
        validator();
      }
    },

    handleBackwardNavigation(stepNumber, validator, payloadFetcher) {
      if (this.rfq.highestStepReached == this.activeStep) {
        this.emitStepChange(stepNumber, payloadFetcher);
      }
      else {
        validator().then(isValid => {
          if (isValid) {
            this.emitStepChange(stepNumber, payloadFetcher);
          }
          else {
            return;
          }
        });
      }
    },

    handleForwardNavigation(stepNumber, validator, payloadFetcher) {
      if (!validator) { return; }

      if (stepNumber > this.rfq.highestStepReached) {
        const isUnlockingNextStep = this.activeStep === this.rfq.highestStepReached;

        if (!isUnlockingNextStep) {
          return false;
        }
      }
      const previousStepsValidations = this.getPreviousStepsValidators(stepNumber);

      Promise.all(previousStepsValidations)
        .then(results => {
          if (results.includes(false)) { return false; }

          return validator();
        })
        .then(isValid => {
          if (isValid) {
            this.emitStepChange(stepNumber, payloadFetcher, true);
          }
        });
    },

    getPreviousStepsValidators(stepNumber) {
      return Array.from({ length: stepNumber - 1 }, (_, i) => i + 1)
        .map(step => this.validators[step])
        .filter(validator => validator)
        .map(validator => validator());
    },

    isUnreachableStep(step) {
      return step > this.rfq.highestStepReached;
    },

    goingBackwards(stepNumber) {
      return stepNumber < this.activeStep;
    },

    emitStepChange(stepNumber, payloadFetcher, shouldUpdateMaxStep = false) {
      const payload = payloadFetcher ? payloadFetcher() : null;

      if (shouldUpdateMaxStep && payload?.rfq) {
        payload.rfq = { ...payload.rfq, shouldUpdateMaxStep: true };
      }

      this.$emit('step-change', {
        newActiveStep: stepNumber,
        oldActiveStep: this.activeStep,
        stepChangePayload: payload
      });

      return true;
    }

  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .step-header {
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .step-item {
    display: none;
    padding-right: 5px;
    cursor: pointer;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding-left: 5px;
      padding-bottom: 15px;
    }

    .stepper-label-text {
      font-size: 15px;
      font-weight: 600;
    }

    &.selected {
      display: inline-block;
      border-bottom: 4px solid $k-blue;
      padding-bottom: 11px;
    }
  }

  .add-spacer-after {
    position: relative;

    &::after {
      @media (min-width: $screen-sm-min) {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 15px);
        height: 1px;
        background: $k-gray;
        // this is the calculation for the space between objects that have
        // display:flex and justify-content: space-between.
        width: calc(((var(--total-width) - var(--items-width)) / var(--spacer-count)) - 20px);
      }
    }
  }

  .stepper-label {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: $white;
    border-radius: 50%;
    font-weight: 700;
    text-align: center;
    line-height: 24px;

    &.status-active {
      display: inline-block;
      background: $k-blue;
    }

    &.status-incomplete {
      background: $pill-gray;
    }

    &.status-complete {
      background: $k-blue;

      .base-icon {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 16px;
        height: 16px;
        fill: $white;
      }
    }
  }
</style>
