<template>
  <priori-modal ref="removeFirmModal" modal-id="remove-firm-modal" :title="confirmationTitle">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="pseudo-link-blue pill-gray-text semibold-weight" @click="openModal"><svg-icon name="trash" class="base-icon smaller right-10"></svg-icon>Remove Firm</button>
    </template>

    <div class="size-text-14px bottom-30">
      Are you sure you want to remove {{ rfqLawFirmQuote.firmName }}<span v-if="lawyerCount"> and {{ lawyerCount }} suggested lawyers</span> from this RFP?
    </div>

    <div class="row tight-columns">
      <div class="col-sm-5">
        <button type="button" class="nv-button-blue" @click="proceedToRemoveFirm">Yes, Delete this Firm</button>
      </div>

      <div class="col-sm-4 top-20-xs">
        <button type="button" class="nv-button-white" @click="cancelRemoveFirm">No, Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RFQLawFirmQuote from 'resources/scout/rfq-law-firm-quote.js';

export default {
  name: 'RfqRemoveFirm',

  components: {
    PrioriModal,
    SvgIcon
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    rfqLawFirmQuote: {
      type: Object,
      required: true
    }
  },

  computed: {
    confirmationTitle() {
      return `Delete Law Firm ${this.rfqLawFirmQuote.firmName}`;
    },

    lawyerCount() {
      return this.rfqLawFirmQuote.suggestedLawyers.length;
    }
  },

  methods: {
    proceedToRemoveFirm() {
      this.removeFirm(this.rfqLawFirmQuote);
    },

    removeFirm(lawFirmQuote) {
      if (lawFirmQuote.id === null) {
        this.rfq.lawFirmQuotes = this.rfq.lawFirmQuotes.filter((rfq) => rfq.lawFirmId !== lawFirmQuote.lawFirmId);
        this.$refs.removeFirmModal.closeModal();
      }
      else {
        RFQLawFirmQuote.delete({ id: lawFirmQuote.id })
          .then(() => {
            this.rfq.lawFirmQuotes = this.rfq.lawFirmQuotes.filter((rfq) => rfq.id !== lawFirmQuote.id);
            this.$refs.removeFirmModal.closeModal();
          });
      }
    },

    cancelRemoveFirm() {
      this.$refs.removeFirmModal.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .pill-gray-text {
    &:hover, &:focus {
      color: $pill-gray;
    }
  }
</style>
