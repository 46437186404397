<template>
  <th class="firm-detail-cell">
    <div class="relative-container height-100">
      <a class="firm-name-container size-text-15px bold-weight text-center" :href="`/scout-company/law-firms/${quote.lawFirmBridge.id}`" target="_blank">
        {{ quote.lawFirm.name }}
      </a>

      <div :class="['size-text-11px text-uppercase semibold-weight text-center top-4', statusColorClass]">
        {{ statusText }}
      </div>

      <div class="top-4">
        <div class="vertical-center center-justified">
          <rating-circle class="right-5" :rating="rating"></rating-circle>
          <span class="bold-weight right-5">{{ displayScore }}</span>
          <span class="pill-gray-text bold-weight right-5">&#8231;</span>
          <span class="pill-gray-text size-text-12px">{{ reviewCountDisplay }}</span>
        </div>
      </div>

      <div class="row tight-columns buttons-container">
        <div class="col-xs-6">
          <edit-note-modal :quote="quote" :rfq="rfq">
            <template #modal-trigger="{ openModal }">
              <button type="button" class="nv-button-white" @click="openNotes(openModal)"><svg-icon name="note" class="base-icon right-5"></svg-icon>{{ noteTitle }}</button>
            </template>
          </edit-note-modal>
        </div>

        <div class="col-xs-6">
          <button type="button" class="nv-button-white" @click="openMessaging"><svg-icon name="envelope" class="base-icon vertical-top-text right-5"></svg-icon>Message</button>
        </div>
      </div>
    </div>
  </th>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import EditNoteModal from 'vue-app/scout/client/rfqs/edit-note-modal.vue';
import MessagingService from 'services/scout/messaging-service.js';

export default {
  name: 'FirmHeaderCell',

  components: {
    RatingCircle,
    SvgIcon,
    EditNoteModal
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    }
  },

  computed: {
    reviewCountDisplay() {
      return this.$pluralize('Review', this.reviewCount, true);
    },

    reviewCount() {
      return this.quote.lawFirmBridge.reviewsSummary?.count || 0;
    },

    score() {
      return this.quote.lawFirmBridge.reviewsSummary?.score;
    },

    rating() {
      const percent = this.score ? this.score / 10 * 100 : 0;
      return { percent: percent };
    },

    displayScore() {
      return this.score ? `${Number(this.score).toFixed(1)}` : 'N/A';
    },

    isSelected() {
      return this.quote.status === 'selected_by_client';
    },

    // We're only handling two statuses for now, so it's either selected or received
    statusText() {
      return this.isSelected ? 'Selected' : 'Quote Received';
    },

    statusColorClass() {
      return this.isSelected ? 'green-text' : 'high-match-score-blue-text';
    },

    noteTitle() {
      if (this.quote.notes.length === 0) {
        return 'Leave Note';
      }

      return `Notes (${this.quote.notes.length})`;
    }
  },

  methods: {
    openNotes(openModalFn) {
      MessagingService.closeMessaging();
      openModalFn();
    },

    openMessaging() {
      MessagingService.closeMessaging();
      MessagingService.openMessaging(this.rfq, this.quote);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  th.firm-detail-cell {
    position: sticky;
    top: 0;
    height: 170px;
    background-color: $white;

    &:first-child {
      background-color: $bg-color;
    }

    .firm-name-container {
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-height: 21px;
      color: $k-darker-gray;
    }

    .buttons-container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
</style>
