<template>
  <div>
    <scout-titlebar
      title="Create New RFP">
      <template #actions>
        <button type="button" class="titlebar-button secondary-btn-blue" @click="saveAndExit">Save and Exit</button>
      </template>
    </scout-titlebar>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <loading-section name="rfqForm">
            <rfq-create-form
              :rfq="rfq"
              :resource-lists="resourceLists"
              :saved-searches="savedSearches"
              :available-firms="availableFirms"
              :active-step="activeStep"
              @step-change="handleStepChange"
              @contact-admins="contactScoutAdmins"
              :on-update-supporting-documents="onUpdateSupportingDocuments"
              :on-destroy-all-law-firm-quotes="onDestroyAllLawFirmQuotes"
              :on-save="onSave">
            </rfq-create-form>
          </loading-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqCreateForm from 'vue-app/scout/client/rfqs/create/create-form.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ScoutTitlebar from 'vue-app/scout/shared/scout-titlebar.vue';

export default {
  name: 'RfqCreate',

  components: {
    RfqCreateForm,
    LoadingSection,
    ScoutTitlebar
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    savedSearches: {
      type: Array,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    onUpdateSupportingDocuments: {
      type: Function,
      required: true
    },

    onDestroyAllLawFirmQuotes: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      activeStep: 1
    };
  },

  methods: {
    saveAndExit() {
      const firmsEnabled = this.rfq.firmsEnabled;
      const destroyFirms = this.onDestroyAllLawFirmQuotes;

      LoadingService.loading('rfqForm');

      this.onSave({ rfq: this.rfq, step: this.activeStep })
        .then(() => {
          if (!firmsEnabled) { destroyFirms(); }
          window.location.href = '/scout-company/rfps';
        });
    },

    contactScoutAdmins(data) {
      this.rfq = data.rfq;
    },

    handleStepChange(params) {
      const rfq = params.rfq || this.rfq;

      this.onSave({ rfq: rfq, step: params.oldActiveStep })
        .then(() => {
          if (!this.rfq.firmsEnabled) { this.onDestroyAllLawFirmQuotes(); }
          this.activeStep = params.newActiveStep;
        });
    }
  }
};
</script>
