<template>
  <div class="scroll-view">
    <div v-if="localCompareData.length >= 100" class="bottom-20 text-italic pill-gray-text">
      Displaying only the top 100 results.
    </div>

    <table class="table custom-table">
      <colgroup>
        <col class="col-1">
        <col class="col-2">
        <col class="col-3">
      </colgroup>

      <thead>
        <tr>
          <th class="rank-col">Rank</th>
          <th class="item-col"><span class="text-capitalize">{{ formattedResourceType }}</span></th>
          <th class="score-col">Score</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in localCompareData" :key="item.id">
          <td class="rank-col semibold-weight">
            {{ index + 1 }}
          </td>

          <td class="item-col text-ellipsis">
            <div class="text-ellipsis name-box">
              <a href="" class="darker-gray-text hover-underline" @click.prevent="onItemSelected(item.id)">{{ item.full_name }}</a>
            </div>
          </td>

          <td v-if="showCircleGraph(item.count, selectedComparison)" class="score-col">
            <rating-circle
              class="inline-block vertical-middle right-5"
              :rating="convertToPercentObject(item.score)"
              :color-class="circleFillColor"
              :dot-color-class="dotColor">
            </rating-circle>
            <span class="bold-weight">{{ item.score.toFixed(1) }}/10</span><span class="pill-gray-text review-count"><span class="separator hidden-xs"></span>{{ item.count }} reviews</span>
          </td>

          <td v-else-if="showRatingBar(item.count, selectedComparison)" class="score-col">
            <div class="row md-columns vertical-center-not-xs">
              <div class="col-sm-8 rating-bar-container">
                <rating-bar
                  :rating="item.score"
                  :max-rating="5"
                  :fill-color-class="fillColor"
                  :dot-color-class="dotColor">
                </rating-bar>
              </div>

              <div class="col-sm-4 top-5-xs">
                {{ item.score.toFixed(1) }}/5
              </div>
            </div>
          </td>

          <td v-else class="score-col">
            <span class="bold-weight score">N/A</span><span class="pill-gray-text review-count"><span class="separator hidden-xs"></span>No Reviews</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import RatingBar from 'vue-app/scout/shared/rating-bar.vue';

export default {
  name: 'ComparisonTable',

  components: {
    RatingCircle,
    RatingBar
  },

  props: {
    comparisonData: {
      type: Array,
      required: true
    },

    selectedComparison: {
      type: String,
      required: true
    },

    resourceType: {
      type: String,
      required: true
    },

    onItemSelected: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      localCompareData: []
    };
  },

  computed: {
    formattedResourceType() {
      return this.resourceType.replace(/_/g, ' ');
    },

    dotColor() {
      return this.resourceType === 'lawyers' ? 'blue-dot' : 'purple-dot';
    },

    fillColor() {
      return this.resourceType === 'lawyers' ? 'blue-purple-background' : 'light-to-dark-blue-background';
    },

    circleFillColor() {
      return this.resourceType === 'lawyers' ? '' : 'blue-rating-indicator';
    }
  },

  watch: {
    comparisonData: {
      handler(newVal) {
        this.localCompareData = newVal;
      },
      deep: true
    }
  },

  mounted() {
    this.localCompareData = this.comparisonData;
  },

  methods: {
    convertToPercentObject(score) {
      return { percent: score * 10 };
    },

    showCircleGraph(reviewsCount, comparison) {
      return reviewsCount > 0 && comparison === 'recommendation';
    },

    showRatingBar(reviewsCount, comparison) {
      return reviewsCount > 0 && comparison !== 'recommendation';
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .scroll-view {
    max-height: 600px;
    overflow-y: auto;
    max-width: 100%;
  }

  .custom-table {
    margin-bottom: 0;
    max-height: 80%;
    overflow-y: auto;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;

    > thead > tr > th {
      padding-top: 0;
      padding-bottom: 4px;
      border-bottom: 1px solid $medium-gray;
      font-weight: 600;
    }

    > tbody > tr > td {
      margin-right: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      border-top: none;
      border-bottom: 1px solid $medium-gray;
      line-height: 20px;
    }

    > thead > tr > th:first-child, > tbody > tr > td:first-child {
      width: 18%;

      @media (min-width: $screen-sm-min) {
        width: 10%;
      }
    }

    .rank-col {
      padding-left: 0;
      text-align: center;
    }

    .item-col {
      padding-left: 8px;

      @media (min-width: $screen-sm-min) {
        padding-left: 18px;
      }

      @media (min-width: $screen-lg-min) {
        padding-left: 28px;
      }
    }

    .score-col {
      padding-right: 10px;
    }
  }

  .hover-underline:hover {
    color: inherit;
    text-decoration: underline;
  }

  .separator {
    &::before {
      content: "•";
      display: inline-block;
      margin: 0 8px;
    }
  }

  .review-count {
    display: block;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  .row.md-columns > [class*=col-].rating-bar-container {
    padding-right: 15px;
  }
</style>
