<template>
  <stepper
    @step-change="handleStepChange"
    :steps="steps"
    :active-step="activeStep"
    :rfq="rfq">
    <template #default="{ moveToNextStep, moveToPrevStep, registerValidator, registerPayloadFetcher }">
      <rfq-step-recipients
        v-if="activeStep === 1"
        :next-step="moveToNextStep"
        :prev-step="moveToPrevStep"
        :register-validator="registerValidator"
        :rfq="rfq"
        :resource-lists="resourceLists"
        :saved-searches="savedSearches"
        :available-firms="availableFirms"
        :is-feature-lab="currentProduct.isFeatureLab"
        :marketplace-rfqs-enabled="marketplaceRfqsEnabled"
        :on-destroy-all-law-firm-quotes="onDestroyAllLawFirmQuotes"
        :on-save="onSave"
        @contact-admins="onContactAdmins">
      </rfq-step-recipients>

      <rfq-step-project-details
        ref="projectDetails"
        v-show="activeStep === 2"
        :next-step="moveToNextStep"
        :prev-step="moveToPrevStep"
        :register-validator="registerValidator"
        :register-payload-fetcher="registerPayloadFetcher"
        :rfq="rfq"
        :on-update-supporting-documents="onUpdateSupportingDocuments"
        :on-save="onSave">
      </rfq-step-project-details>

      <rfq-step-questions
        v-if="activeStep === 3"
        :next-step="moveToNextStep"
        :prev-step="moveToPrevStep"
        :register-validator="registerValidator"
        :register-payload-fetcher="registerPayloadFetcher"
        :rfq="rfq"
        :on-save="onSave">
      </rfq-step-questions>

      <rfq-step-review
        v-if="activeStep === 4"
        :next-step="moveToNextStep"
        :prev-step="moveToPrevStep"
        :register-validator="registerValidator"
        :rfq="rfq">
      </rfq-step-review>
    </template>
  </stepper>
</template>

<script>
import Stepper from 'vue-app/scout/client/rfqs/create/stepper.vue';
import RfqStepRecipients from 'vue-app/scout/client/rfqs/create/step-recipients.vue';
import RfqStepProjectDetails from 'vue-app/scout/client/rfqs/create/step-project-details.vue';
import RfqStepQuestions from 'vue-app/scout/client/rfqs/create/step-questions.vue';
import RfqStepReview from 'vue-app/scout/client/rfqs/create/step-review.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqCreateForm',

  components: {
    Stepper,
    RfqStepRecipients,
    RfqStepProjectDetails,
    RfqStepQuestions,
    RfqStepReview
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    savedSearches: {
      type: Array,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    onUpdateSupportingDocuments: {
      type: Function,
      required: true
    },

    onDestroyAllLawFirmQuotes: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    activeStep: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      steps: [
        { number: 1, label: 'Recipients' },
        { number: 2, label: 'Project Details' },
        { number: 3, label: 'Law Firm Questions' },
        { number: 4, label: 'Review & Send' }
      ]
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', user: 'currentUser' }),

    marketplaceRfqsEnabled() {
      return this.user.workspace.marketplaceRfqsEnabled;
    }
  },

  watch: {
    activeStep(newStep) {
      if (newStep === 2 && this.$refs.projectDetails) {
        this.$refs.projectDetails.clearValidation();
      }
    }
  },

  methods: {
    handleStepChange(payload) {
      this.$emit('step-change', { newActiveStep: payload.newActiveStep, oldActiveStep: payload.oldActiveStep, rfq: payload.stepChangePayload?.rfq });
    },

    onContactAdmins(data) {
      this.$emit('contact-admins', data);
    }
  }
};
</script>
