<template>
  <div>
    <div class="shadowed-box">
      <div class="box-content extra-padded">
        <div class="size-text-17px bold-weight bottom-5">
          Choose Questions for Recipients
        </div>

        <div class="bottom-30">
          Select a template to see and edit the questions you would like RFP recipients to answer regarding your project.
        </div>

        <div class="blue-accent a-form">
          <div class="top-label">
            <label for="k-toggle-button-selected-template">Select a Template</label>

            <div v-if="currentUserIsAdmin" class="top-5-xs">
              <a href="" class="dark-gray-link bottom-5" @click.prevent="manageTemplates">Manage Templates</a>
            </div>
          </div>

          <dropdown-select
            ref="templateDropdown"
            id-label="selected-template"
            class="top-20-xs"
            placeholder="Please Select"
            v-model="currentTemplateId"
            @change="onTemplateChange"
            value-key="id"
            label-key="name"
            :invalid="submitAttempted && formInvalid"
            :initial-label="localQuestionGroups.length > 0 ? getCurrentTemplateName() : null"
            :options="rfqTemplates.map(obj => ({ name: obj.name, id: obj.id }))">
          </dropdown-select>

          <div v-if="submitAttempted && formInvalid" class="error-text top-5">
            * Required
          </div>

          <div v-if="hasEdits" class="top-5 pill-gray-text size-text-12px text-italic">
            Template has been edited for this RFP
          </div>
        </div>

        <rfq-template-questions
          v-if="displayTemplateQuestions"
          class="top-30"
          :is-editing="true"
          :question-groups="localQuestionGroups"
          :show-template-heading="false">
        </rfq-template-questions>
      </div>
    </div>

    <div class="d-flex flex-space-between top-30">
      <button type="button" class="bold-weight gap-10 pseudo-link-blue vertical-center blue-text" @click="previous"><svg-icon name="arrow-left" class="base-icon"></svg-icon>Previous</button>

      <button type="button" class="bold-weight gap-10 pseudo-link-blue vertical-center blue-text" @click="next">Continue<svg-icon name="arrow-right" class="base-icon"></svg-icon></button>
    </div>

    <div v-if="submitAttempted && formInvalid" class="red-link text-right top-5">
      * Please complete all required fields
    </div>

    <priori-modal ref="modal" title="Switch Template">
      <template #default="{ closeModal }">
        <div class="bottom-30 size-text-14px">
          Are you sure you want to switch templates? Any changes or custom questions you have added will not carry over.
        </div>

        <div class="row tight-columns">
          <div class="col-sm-5">
            <button type="button" name="switchTemplate" class="primary-btn-blue" @click="switchTemplate">Switch Template</button>
          </div>

          <div class="col-sm-3 top-10-xs">
            <button type="button" class="nv-button-white" @click="onCloseModal">Cancel</button>
          </div>
        </div>
      </template>
    </priori-modal>
  </div>
</template>

<script>
import RfqTemplateQuestions from 'vue-app/scout/client/settings/rfqs/templates/questions.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

import RFQTemplate from 'resources/scout/rfq-template.js';

import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

import { mapState, mapActions } from 'pinia';
import { orderBy, pick, isEqual } from 'lodash';

export default {
  name: 'RfqStepQuestions',

  components: {
    RfqTemplateQuestions,
    DropdownSelect,
    PrioriModal,
    SvgIcon
  },

  props: {
    nextStep: {
      type: Function,
      required: true
    },

    prevStep: {
      type: Function,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    registerValidator: {
      type: Function,
      required: true
    },

    registerPayloadFetcher: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      currentTemplateId: this.rfq.baseQuestionTemplateId,
      currentTemplateName: null,
      previousTemplateId: null, //for keeping track if they cancel on modal warning
      hasEdits: false,
      justSwitchedTemplates: true,
      localQuestionGroups: [],
      stepNumber: 3,
      submitAttempted: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    ...mapState(useRfqTemplateStore, {
      storeTemplate: 'rfqTemplate',
      rfqTemplates: 'rfqTemplates',
      rfqSelectedTemplate: 'rfqSelectedTemplate'
    }),

    hasSelectedTemplate() {
      return this.currentTemplateId !== null || (this.rfq.question_groups && this.rfq.question_groups.length > 0);
    },

    currentUserIsAdmin() {
      return this.user?.admin;
    },

    displayTemplateQuestions() {
      return this.currentTemplateId !== null || this.localQuestionGroups.length > 0;
    },

    formInvalid() {
      return !(this.rfq.question_groups && this.rfq.question_groups.length > 0);
    }
  },

  mounted() {
    RFQTemplate.query({ view: 'show', all_templates: true })
      .then((response) => {
        this.setTemplates(structuredClone(response.templates));
        this.initializeQuestionGroups();
      });

    useRfqTemplateStore().$subscribe(() => {
      this.updateLocalFromTemplateChange();
    });

    this.registerValidator(this.stepNumber, this.validateStep);
    this.registerPayloadFetcher(this.stepNumber, this.fetchPayload);
  },

  methods: {
    ...mapActions(useRfqTemplateStore, [
      'setTemplates',
      'setRfqGroupTemplates',
      'setSelectedTemplateByName',
      'setSelectedTemplateById'
    ]),

    openModal() {
      this.$refs.modal.openModal();
    },

    formIsValid() {
      return (this.rfq.question_groups && this.rfq.question_groups.length > 0);
    },

    validateStep() {
      this.didAttemptSubmit();

      return new Promise((resolve) => {
        resolve(this.formIsValid());
      });
    },

    didAttemptSubmit() {
      this.submitAttempted = true;
    },

    fetchPayload() {
      this.updateQuestionGroups();
      return { rfq: this.rfq };
    },

    updateLocalFromTemplateChange() {
      if (this.hasSelectedTemplate && !this.justSwitchedTemplates) {
        this.localQuestionGroups = [...this.storeTemplate.questionGroupTemplatesAttributes];
        this.updateQuestionGroups();
        this.hasEdits = this.checkForEdits();
      }
      else {
        this.justSwitchedTemplates = false;
      }
    },

    isCustomSectionWithNoQuestions(section) {
      return section.position >= 5 && section.questionTemplatesAttributes.filter(q => !q._destroy).length === 0;
    },

    allQuestionsStagedForRemoval(group) {
      return group.questionTemplatesAttributes.every(question => question._destroy);
    },

    pruneEmptySections(questionGroups) {
      questionGroups = orderBy(questionGroups, 'position');

      for (let i = questionGroups.length - 1; i >= 0; i--) {
        const section = questionGroups[i];

        // Update `isShown` for standard questions
        if (section.position < 5 && (section.questionTemplatesAttributes.length === 0)) {
          section.isShown = false;
        }
        else if (this.isCustomSectionWithNoQuestions(section)) {
          questionGroups.splice(i, 1);

          // Decrement position by 1 for every object after empty question section
          for (let j = i; j < questionGroups.length; j++) {
            questionGroups[j].position -= 1;
          }
        }
      }
      return questionGroups;
    },

    initializeQuestionGroups() {
      this.currentTemplateId = this.rfq.baseQuestionTemplateId;
      this.previousTemplateId = this.currentTemplateId;

      if (this.rfq.questionGroups && this.rfq.questionGroups.length > 0) {
        this.localQuestionGroups = this.formatRFQQuestionGroupsForTemplates(this.rfq.questionGroups);
        this.setRfqGroupTemplates(this.localQuestionGroups);
        this.hasEdits = this.checkForEdits();
        this.rfq.question_groups = [...this.rfq.questionGroups];
      }
    },

    //checks current question group against the template it was based on
    checkForEdits() {
      const template = structuredClone(this.rfqTemplates.find((t) => t.id === this.currentTemplateId));
      const originalQuestions = template.questionGroupTemplatesAttributes.map(group => ({
        ...pick(group, ['name', 'position']),
        questionTemplatesAttributes: group.questionTemplatesAttributes.map(question =>
          pick(question, ['questionText', 'questionType', 'position'])
        )
      }));
      const currentQuestions = this.localQuestionGroups.map(group => ({
        ...pick(group, ['name', 'position']),
        questionTemplatesAttributes: group.questionTemplatesAttributes.map(question =>
          pick(question, ['questionText', 'questionType', 'position'])
        )
      }));
      return !isEqual(currentQuestions, originalQuestions);
    },

    getCurrentTemplateName() {
      let templateName = 'Standard Questions';

      if (this.currentTemplateId !== null) {
        const templateId = this.currentTemplateId === this.previousTemplateId ? this.currentTemplateId : this.previousTemplateId;
        const localTemplate = structuredClone(this.rfqTemplates.find((t) => t.id === templateId));

        if (localTemplate) {
          this.currentTemplateName = localTemplate.name;
          templateName = localTemplate.name;
        }
      }

      return templateName;
    },

    // So we can use the RFQ format of question groups in templates seemlessly
    formatRFQQuestionGroupsForTemplates(questionGroups) {
      const sortedGroups = orderBy(questionGroups, ['position'], ['asc']);

      return sortedGroups.map(group => {
        return {
          id: group.id,
          name: group.name,
          position: group.position,
          handlesLawyers: group.handlesLawyers || false,
          isShown: group.isShown,
          questionTemplatesAttributes: orderBy(group.questions, ['position'], ['asc']).map(question => {
            return {
              id: question.id,
              questionText: question.questionText,
              questionType: question.questionType,
              isCustomQuestion: question.isCustomQuestion,
              isIncluded: true,
              isRequired: question.isRequired,
              position: question.position,
              prerequisiteQuestionAnswer: question.prerequisiteQuestionAnswer || null,
              prerequisiteQuestionId: question.prerequisiteQuestionId || null,
              questionOptions: question.questionOptions || [],
              shortname: question.shortname || '',
              isPricingPhaseQuestion: question.isPricingPhaseQuestion
            };
          })
        };
      });
    },

    onTemplateChange() {
      if (this.hasEdits) {
        this.openModal();
      }
      else {
        this.switchTemplate();
      }
    },

    switchTemplate() {
      let localTemplate = '';
      if (this.currentTemplateId === null) {
        localTemplate = structuredClone(this.rfqTemplates.find((t) => t.id === null));
      }
      else {
        localTemplate = structuredClone(this.rfqTemplates.find((t) => t.id === this.currentTemplateId));
      }

      this.hasEdits = false;
      this.justSwitchedTemplates = true;
      this.setRfqGroupTemplates(localTemplate.questionGroupTemplatesAttributes);
      this.applyTemplate(this.currentTemplateId);
      this.rfq.base_question_template_id = localTemplate.id;
      this.$refs.modal.closeModal();
    },

    applyTemplate(templateId) {
      const selectedTemplate = this.rfqTemplates.find((t) => t.id === templateId);
      if (selectedTemplate) {
        if (templateId == null) {
          this.setSelectedTemplateByName(selectedTemplate.name);
        }
        else {
          this.setSelectedTemplateById(templateId);
        }
        this.currentTemplateId = templateId;
        this.previousTemplateId = this.currentTemplateId;
        this.localQuestionGroups = selectedTemplate.questionGroupTemplatesAttributes;
        this.$set(this.rfq, 'question_groups', [...this.localQuestionGroups]);
      }
    },

    manageTemplates() {
      this.updateQuestionGroups();
      this.onSave({ rfq: this.rfq, step: this.stepNumber });
      window.location.href = '/scout-company/settings/question-templates';
    },

    next() {
      this.didAttemptSubmit();
      if (this.formInvalid) { return; }
      this.updateQuestionGroups();
      this.nextStep({ rfq: this.rfq });
    },

    updateQuestionGroups() {
      this.rfq.question_groups = this.pruneEmptySections(this.localQuestionGroups);
    },

    previous() {
      this.didAttemptSubmit();
      this.updateQuestionGroups();
      this.prevStep({ rfq: this.rfq });
    },

    onCloseModal() {
      this.currentTemplateId = this.previousTemplateId;
      this.$refs.templateDropdown.selectedOption = this.currentTemplateName;
      this.$refs.modal.closeModal();
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .blue-accent {
    background-color: $blue-10;
    padding: 20px;
    border-radius: $border-radius-large;
    position: relative;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }

    .top-label {
      @media (min-width: $screen-sm-min) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }
    }
  }
</style>
